import { Snackbar, Button } from '@mui/material';
import { useState } from 'react';

interface ShareButtonProps {
  link: string;
}

export const ShareButton: React.FC<ShareButtonProps> = ({ link }) => {
  const [open, setOpen] = useState(false);

  const handleClick = async () => {
    try {
      await navigator.clipboard.writeText(link);
      setOpen(true);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  const handleClose = (event: any, reason: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <div>
      <Button onClick={handleClick}                variant="contained"
                style={{
                  backgroundColor: 'white',
                  borderRadius: '4px',
                  color: 'black',
                  height: '50px',
                  marginLeft: '10px',
                  fontSize: '0.8rem',
                  textTransform: 'none'
                }}>share</Button>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Link copied to clipboard! Pay it forward:)"
      />
    </div>
  );
};