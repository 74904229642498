import React, { CSSProperties } from 'react';

const styles: { [key: string]: CSSProperties } = {
    container: {
        marginTop: 'auto',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        textAlign: 'center',
        paddingBottom: '24px'
    },
    contactInfo: {
        color: 'darkgray',
        fontSize: '10px',
    },
    versionInfo: {
        color: 'lightgray',
        fontSize: '12px',
        fontWeight: 'bold',
        paddingBottom: '4px'
    },
    betaVersionLink: {
        color: 'lightgray',
        textDecoration: 'underline',
    },
    contactLink: {
        color: 'darkgray',
        textDecoration: 'none',
    },
};

export const InfoContainer = () => (
    <div style={styles.container} className="info-container">
        <div style={styles.versionInfo}>
            reso vol 1.2 2023 , <a href="https://forms.gle/AQ79J6VV7cPTS7xa9" style={styles.betaVersionLink}>
                beta version
            </a>
        </div>
        <div style={styles.contactInfo}>
            <a href="mailto:rh.raphaelharel@gmail.com" style={styles.contactLink}>
                rh.raphaelharel@gmail.com
            </a>
        </div>
    </div>
);