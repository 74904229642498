import { CSSProperties, useEffect, useState } from 'react';
import { Modal, Grid, Button, Autocomplete, TextField, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import RingLoader from './animations/RingLoader';
import { IPlaylist, IResponseData } from './types/backendTypes';
import { SERVER_URL } from './config';
import Avatar from '@mui/material/Avatar';
import RImage from './images/R.png';

import cozyHours from './images/modalImages/cozyHours.png';
import deepHouse from './images/modalImages/deepHouse.png';
import roadtrip from './images/modalImages/roadtrip.png';
import workout from './images/modalImages/workout.png';

import { InfoContainer } from './components/InfoContainer';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { ClipboardCopy } from './components/ClipboardCopy';
import { ShareButton } from './components/ShareButton';

export const Create = () => {
  const [loading, setLoading] = useState(true); // set initial state to true
  const [modalOpen, setModalOpen] = useState(false);
  const [fade, setFade] = useState(false);
  const [playlists, setPlaylists] = useState<IPlaylist[]>([]); // state for storing playlist IDs
  const [selectedPlaylistIndex, setSelectedPlaylistIndex] = useState(0);
  const [playlistLength, setPlaylistLength] = useState(1.5);
  const [isButtonGreen, setIsButtonGreen] = useState(true);
  const [showCopySuccess, setShowCopySuccess] = useState(false);

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(`${window.location.origin}/join/${playlists[selectedPlaylistIndex].id}`);
      setShowCopySuccess(true);
      setTimeout(() => setShowCopySuccess(false), 2000);  // Hide after 2 seconds
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleModalOptionClick = (option: string) => {
    console.log(option);
    createPlaylist(option)
    handleCloseModal();
  };

  const handlePlaylistLengthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPlaylistLength(Number(event.target.value));
    setIsButtonGreen(false);
  };

  const headers = new Headers();

  const accessToken = localStorage.getItem('accessToken');
  const userId = localStorage.getItem('userId');
  if (accessToken) headers.append('accessToken', accessToken);
  if (userId) headers.append('userId', userId);

  //preload images
  const imagesToLoad = [RImage, cozyHours, deepHouse, roadtrip, workout];
  useEffect(() => {
    imagesToLoad.forEach((image) => {
      const img = new Image();
      img.src = image;
    });
  }, []);

  useEffect(() => {
    const load = async () => {
      try {
        const response = await fetch(`${SERVER_URL}/songs/load`, {
          credentials: 'include',
          headers
        });

        if (response.status === 200) {
          const data: IResponseData = await response.json();
          console.log(data)
          data.playlists.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
          setPlaylists(data.playlists);
          setLoading(false);
          setFade(true);
        }
      } catch (error) {
        console.error(error);
      }
    };
    load();
  }, []);

  const handlePlaylistSelect = (event: React.SyntheticEvent<Element, Event>, newValue: number | null) => {
    if (newValue !== null) {
      setSelectedPlaylistIndex(newValue);
    }
  };

  const createPlaylist = async (playlistLink: string) => {
    setLoading(true);
    try {
      const headers = new Headers();
      const accessToken = localStorage.getItem('accessToken');
      const userId = localStorage.getItem('userId');
      headers.append('playlistRefLink', playlistLink)
      if (accessToken) headers.append('accessToken', accessToken);
      if (userId) headers.append('userId', userId);

      const response = await fetch(
        `${SERVER_URL}/playlists/create`,
        {
          credentials: 'include',
          headers
        }
      );

      if (!response.ok) {
        throw new Error('Failed to create playlist');
      }

      const data = await response.json();
      if(data){
        console.log("data returned from backend: ")
        console.log(data)
      }
      if (data.playlists) {
        console.log(data.playlists)
        setPlaylists(data.playlists)
        setSelectedPlaylistIndex(data.playlists.length - 1)
        console.log(playlists[selectedPlaylistIndex])
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };


  const handleUserRemove = async (userId: string) => {
    // Remove the user from the view
    const newPlaylists = [...playlists];
    newPlaylists[selectedPlaylistIndex].userIds = newPlaylists[selectedPlaylistIndex].userIds.filter(id => id !== userId);
    newPlaylists[selectedPlaylistIndex].users = newPlaylists[selectedPlaylistIndex].users.filter(user => user.id !== userId);

    setPlaylists(newPlaylists);

    // const playlistId = playlists[selectedPlaylistIndex].id;
    // try {
    //   // Send a request to the server to remove this user
    //   const response = await fetch(`${SERVER_URL}/playlist/${playlistId}/user/${userId}`, {
    //     method: 'DELETE',
    //   });
    //   if (!response.ok) {
    //     throw new Error('Failed to remove user');
    //   }
    // } catch (error) {
    //   console.error('Failed to remove user:', error);
    // }
  };

  const disconnectSpotify = async () => {
    // remove the tokens stored in the frontend, see:
    const accessToken = localStorage.getItem('accessToken');
    localStorage.removeItem('accessToken');
    if(!accessToken) return console.error('No access token found')
    const userId = localStorage.getItem('userId');
    if(!userId) return console.error('No user ID found')
    localStorage.removeItem('userId');

    try {
      const response = await fetch(`${SERVER_URL}/load/disconnectSpotify`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          accessToken: accessToken,
          userId: userId
        }
      });
      if (!response.ok) {
        throw new Error('Failed to disconnect Spotify');
      }
    } catch (error) {
      console.error('Error disconnecting Spotify:', error);
    }
  }

  return (
    <div style={styles.page}>
      {/* <div style={styles.header}>
        <img src={RImage} alt="RImage" style={styles.logo} />
      </div> */}
      <div style={styles.container}>
        {loading ? (
          <RingLoader color='lightgray' />) : (
          <div style={{ ...styles.fadeContainer, opacity: fade ? 1 : 0, flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <div style={{ paddingLeft: '20px' }}>
                <iframe
                  src={playlists[selectedPlaylistIndex].embedLink}
                  width="100%"
                  height="380"
                  allow="encrypted-media"
                  style={{ border: 'none', maxWidth: '300px' }}
                ></iframe>
              </div>
              <div style={styles.usersContainer}>
                {playlists[selectedPlaylistIndex].users.map((user) => (
                  <div
                    key={user.id}
                    style={{
                      position: 'relative',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      marginBottom: '16px'
                    }}
                  >
                    <IconButton
                      style={{
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        backgroundColor: '#fff',
                        borderRadius: '50%',
                        padding: 2,
                      }}
                      onClick={() => handleUserRemove(user.id)}
                      size="small"
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                    <a href={user.profileUrl} target="_blank" rel="noopener noreferrer">
                      {(() => {
                        const imageUrl = user.images[0]?.url;
                        return imageUrl ? (
                          <Avatar
                            style={{ width: '80%', height: 'auto' }}
                            src={imageUrl}
                            alt={user.display_name}
                          />
                        ) : (
                          <Avatar style={{ width: '45px', height: '45px', margin: 20 }}

                          >
                            <PersonIcon />
                          </Avatar>
                        )
                      })()}
                    </a>
                    <p>{user.display_name}</p>
                  </div>
                ))}
              </div>
            </div>
            <Grid container spacing={0.5} style={{ paddingTop: '12px', paddingBottom: '24px' }}>
              <Grid item xs={9}>
                <Autocomplete
                  value={selectedPlaylistIndex}
                  options={playlists.map((playlist, index) => index)}
                  getOptionLabel={(option) => `reso ${playlists.length - option} from ${new Date(playlists[option].createdAt).toLocaleString()}`}
                  fullWidth
                  renderInput={(params) =>
                    <TextField
                      {...params}
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        style: {
                          backgroundColor: 'white',
                          fontSize: '0.8rem',
                          padding: '10px 12px',
                        }
                      }}
                      InputLabelProps={{ style: { color: 'grey', fontSize: '0.8rem' } }}
                    />}
                  onChange={handlePlaylistSelect}
                />
              </Grid>
              <Grid item xs={3}>
                <div style={{ width: '100%' }}>
                  <ShareButton link={`${window.location.origin}/join/${playlists[selectedPlaylistIndex].id}`} />
                </div>
              </Grid>
            </Grid>

            <Modal
              open={modalOpen}
              onClose={handleCloseModal}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <div style={styles.modal}>
                <Grid container spacing={0} style={{ position: 'relative' }}>
                  <div style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    color: 'white',
                    fontWeight: 'bold',
                    fontSize: '1em',
                    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
                    zIndex: 2
                  }}>
                    feeling like...
                  </div>
                  {[
                    { label: 'chillout sunday', img: cozyHours, link: 'https://open.spotify.com/playlist/37i9dQZF1DWUJrRlgpYslH?si=99134800e4ba49bd' },
                    { label: 'deep cuts', img: deepHouse, link: 'https://open.spotify.com/playlist/1iO5gs0rOW5F1VsbFkRq8C?si=95f0003576c94ecf' },
                    { label: 'hype workout', img: workout, link: 'https://open.spotify.com/playlist/37i9dQZF1DX76t638V6CA8?si=1bcda3e2a07a435c' },
                    { label: 'pop freedom', img: roadtrip, link: 'https://open.spotify.com/playlist/37i9dQZF1DWWMOmoXKqHTD?si=5bbfa977d4e3443e' },
                  ].map((option, index) => (
                    <Grid item xs={6} key={index}>
                      <div
                        style={{
                          position: 'relative',
                          cursor: 'pointer',
                          width: '100%',
                          height: 0,
                          paddingBottom: '100%',
                        }}
                        onClick={() => handleModalOptionClick(option.link)}
                      >
                        <img
                          src={option.img}
                          alt={option.label}
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                          }}
                        />
                        <div style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          backgroundColor: 'rgba(0, 0, 0, 0.3)', // This creates a semi-transparent black overlay
                        }} />
                        <div style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          color: 'white',
                          fontWeight: 'bold',
                          fontSize: '1em',
                          textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
                          padding: '10%',
                          boxSizing: 'border-box',
                        }}>
                          {option.label}
                        </div>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </div>
            </Modal>

            <Button
              onClick={handleOpenModal}
              variant="contained"
              style={{
                backgroundImage: `url(${RImage})`,
                backgroundSize: '300',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                borderRadius: '4px',
                color: 'white',
                height: '50px',
                marginLeft: '10px',
                fontSize: '0.8rem',
                textTransform: 'none'
              }}
            >
              create a new reso
            </Button>
            <button onClick={disconnectSpotify}               
              style={{
                background: 'transparent',
                border: '1px solid lightgray',
                color: 'lightgray',
                borderRadius: '10px',
                marginTop: '10px',
                padding: '20px 20px',
                transition: 'background 0.1s',
              }}>Disconnect Spotify</button>
          </div>
        )}
      </div>
    </div>
  );
};


const styles: { [key: string]: CSSProperties } = {
  page: {
    backgroundColor: 'black',
    color: 'white',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,  // this ensures that the container takes up the remaining space
    paddingInline: '16px',
    overflow: 'auto',
  },
  header: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '20px',
    boxSizing: 'border-box',
  },
  logo: {
    height: '100px',
    width: 'auto',
  },
  spinner: {
    display: 'block',
    margin: '0 auto',
  },
  modal: {
    position: 'absolute',
    width: '60vmin',
    height: '60vmin',
    maxWidth: '100%',
    maxHeight: '100%',
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '10px 10px 5px 0px rgba(0,0,0,0.75)',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'auto',
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
    flexGrow: 1,
  },
  fadeContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    transition: 'opacity 1s ease-in-out', // this creates the fade-in effect
  },
  usersContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxHeight: '380px',  // adjust this as necessary
    overflowY: 'auto',
    padding: '10px',
    gap: '10px',
  }
};
