import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Home } from './Home'; 
import { Create } from './Create'; 
import { Join } from './Join'; 

const App = () => {

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/create" element={<Create />} />
        <Route path="/join/:playlistId" element={<Join/>} />
      </Routes>
    </Router>
  );
};

export default App;
