import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SERVER_URL } from './config';
import { CSSProperties } from 'react';
import RImage from './images/R.png';
import { InfoContainer } from './components/InfoContainer';

import { AlertModal } from './components/AlertModal';

export interface CookieData {
  value: string;
  options: {
    domain: string;
  };
}

export const Home = () => {
  const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState(false);
  const [buttonHover, setButtonHover] = useState(false);

  const [alertMessage, setAlertMessage] = useState(null);
  const [alertLink, setAlertLink] = useState(null);

  const urlCode = new URL(window.location.href).searchParams.get('code');

  useEffect(() => {

    const checkLoggedInStatus = async () => {
      if (urlCode) {
        console.log("URL CODE: ", urlCode);
        console.log("redirected from spotify, checking...");
        const success = await fetchTokens(urlCode, setAlertMessage, setAlertLink);
        if (success) {
          console.log("CheckLoggedInStatus: success with urlCode- ", success);
          setLoggedIn(true);
          const redirect = localStorage.getItem('redirect');
          console.log(redirect);
          if (redirect) {
            localStorage.removeItem('redirect');
            navigate(redirect);
          } else {
            redirectToCreate();
          }
        }
      } else {
        const success = await fetchTokens(undefined,  setAlertMessage, setAlertLink);
        if (success) {
          console.log("CheckLoggedInStatus: success with no urlCode- ", success);
          setLoggedIn(true);
          const redirect = localStorage.getItem('redirect');
          if (redirect) {
            localStorage.removeItem('redirect');
            navigate(redirect);
          } else {
            redirectToCreate();
          }
        }
      }
    };
    checkLoggedInStatus();

  }, [urlCode]);

  const handleStart = async () => {
    if (!loggedIn) {
      try {
        const headers = new Headers();
        const accessToken = localStorage.getItem('accessToken');
        const userId = localStorage.getItem('userId');
        if (accessToken) headers.append('accessToken', accessToken);
        if (userId) headers.append('userId', userId);

        const response = await fetch(
          `${SERVER_URL}/login/`,
          {
            credentials: 'include',
            headers,
          }
        );

        if (!response.ok) {
          console.error('Error sending login request:', response);
          throw new Error('Error sending login request');
        }

        console.log('Login request sent successfully.');
        const res = await response.json(); // Parse the response as JSON

        if (res && res.authUrl) {
          console.log('Redirecting to:', res.authUrl);
          window.location.href = res.authUrl; // Redirect the browser to the provided URL
        } else {
          console.error('Error parsing login response:', res);
          throw new Error('Error parsing login response');
        }
      } catch (err) {
        console.error('Error occurred during login:', err);
      }
    } else {
      const redirect = localStorage.getItem('redirect');

      if (redirect) {
        localStorage.removeItem('redirect');
        navigate(redirect);
      } else {
        console.log('home cookie: ', document.cookie)
        redirectToCreate();
      }
    }
  };

  const redirectToCreate = () => {
    console.log('Redirecting to Create component...');
    navigate('/create');
  };

  const handleButtonHover = () => {
    setButtonHover(true);
  };

  const handleButtonLeave = () => {
    setButtonHover(false);
  };

  const buttonStyle = {
    background: 'transparent',
    border: '1px solid lightgray',
    color: 'lightgray',
    borderRadius: '10px',
    padding: '10px 20px',
    transition: 'background 0.1s',
    backgroundColor: buttonHover ? 'dimgray' : 'transparent',
  };

  useEffect(() => {
    // Load Google Fonts dynamically
    const link = document.createElement('link');
    link.href = 'https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap';
    link.rel = 'stylesheet';

    document.head.appendChild(link);

    return () => {
      document.head.removeChild(link);
    };
  }, []);

  return (
    <div style={styles.container}>
      {alertMessage && <AlertModal message={alertMessage} link={alertLink} />}
      <img src={RImage} style={styles.icon} alt="Icon" />
      <button
        onClick={handleStart}
        style={buttonStyle}
        onMouseEnter={handleButtonHover}
        onMouseLeave={handleButtonLeave}
      >
        Start
      </button>
      <InfoContainer />
    </div>
  );
}

export const fetchTokens = async (code: string | undefined, setAlertMessage: React.Dispatch<React.SetStateAction<null>>, setAlertLink: React.Dispatch<React.SetStateAction<null>>) => {
  try {
    const headers = new Headers();
    const accessToken = localStorage.getItem('accessToken');
    const userId = localStorage.getItem('userId');
    if (accessToken) headers.append('accessToken', accessToken);
    if (userId) headers.append('userId', userId);

    const response = await fetch(
      `${SERVER_URL}/load/fetchTokens${code ? '?code=' + code : ''}`,
      {
        credentials: 'include',
        headers
      }
    );

    if (!response.ok) {
      console.error('Error: HTTP status', response.status);
      const errorData = await response.json(); // you can log the error message sent by the server
      console.error('Server error message:', errorData.error);
      return false;
    }

    if (response.ok) {
      console.log('response: ', response)
    }

    const data = await response.json();
    if (data) {
      console.log('data: ', data)
    }

    if (data.message) {
      // Display the informational message to the user
      setAlertMessage(data.message);
      setAlertLink(data.link);
    }
    
    if (data.success && data.credentials) {
      console.log('data.body: ', data.credentials)
      localStorage.setItem('accessToken', data.credentials.accessToken);
      localStorage.setItem('userId', data.credentials.userId);
      return true;
    } else {
      return false
    }
  } catch (error) {
    console.error('Error:', error);
    return false;
  }
};

const styles: { [key: string]: CSSProperties } = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    backgroundColor: 'black',
    padding: '0px 0px 0px 0px',
  },
  contactInfo: {
    color: 'darkgray',
    fontSize: '10px',
  },
  versionInfo: {
    color: 'lightgray',
    fontSize: '12px',
    fontWeight: 'bold',
  },
  contactLink: {
    color: 'darkgray',
    textDecoration: 'none',
  },
  title: {
    marginTop: '-50px',
    fontFamily: 'Roboto, sans-serif',
    color: 'white',
    textAlign: 'center',
  },
  icon: {
    width: '100%',
    maxWidth: '300px',
    height: 'auto',
    paddingTop: '25vh'
  },
};


