import { useState } from "react";

interface AlertModalProps {
    message: string | null;
    link: string | null;
  }
  
  
  export const AlertModal: React.FC<AlertModalProps> = ({ message, link }) => {
  const [showModal, setShowModal] = useState(true);  

  if (!showModal) return null;

  return (
    <div style={styles.modal}>
      <div style={styles.modalContent}>
        <p>{message}</p>
        {link && <a href={link} target="_blank" rel="noopener noreferrer">here</a>}
        <button onClick={() => setShowModal(false)}>Close</button>
      </div>
    </div>
  );
};
const styles: { [key: string]: React.CSSProperties } = {
    modal: {
      position: 'fixed',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0,0,0,0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    modalContent: {
      backgroundColor: 'white',
      padding: '20px',
      borderRadius: '5px',
    },
  };
  
