import { FC, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import RingLoader from './animations/RingLoader';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import RImage from './images/R.png';
import { InfoContainer } from './components/InfoContainer';
import './css/Join.css';

import { SERVER_URL } from './config';
import { IPlaylist } from './types/backendTypes';
import { fetchTokens } from './Home';

interface JoinProps {
  origin?: string;
  playlistId?: string;
}

//Props functionality:
//1. Check if user logged in with current tokens, then display using the code
//2. If not, then call the Home component, and set the redirect variable
//3. Home component gets login, redirects to Join
//4. Join calls fetchTokens with login, all clear
export const Join: FC<JoinProps> = ({ origin }) => {
  console.log("Join called")
  const { playlistId } = useParams();
  const [alertMessage, setAlertMessage] = useState(null);
  const [alertLink, setAlertLink] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const [playlist, setPlaylist] = useState<IPlaylist | null>(null);
  const [loading, setLoading] = useState(true);
  const [joining, setJoining] = useState(false);
  const [joined, setJoined] = useState(false)
  const [buttonHover, setButtonHover] = useState(false);
  const urlCode = new URL(window.location.href).searchParams.get('code');

  const navigate = useNavigate();
  useEffect(() => {

    const checkLoggedInStatus = async () => {
      let success;
      if (urlCode) {
        success = await fetchTokens(urlCode, setAlertMessage, setAlertLink);
      } else {
        success = await fetchTokens(undefined,setAlertMessage,setAlertLink);
      }
      setLoggedIn(success)

      if (!success) {
        setLoggedIn(false)
        localStorage.setItem('redirect', '/join/' + playlistId?.toString() || '')
        navigate('/', {})
      } else if(typeof playlistId === 'string') { // Only fetch the playlist if the user is logged in
        const playlist = await fetchPlaylist(playlistId);
        if(playlist){
          setPlaylist(playlist);
          setLoading(false);
        }
      }
    }

    checkLoggedInStatus();
  }, [playlistId]);

  const joinPlaylist = async () => {
    setJoining(true);
    try {
      const headers = new Headers();
      const accessToken = localStorage.getItem('accessToken');
      const userId = localStorage.getItem('userId');
      if (accessToken) headers.append('accessToken', accessToken);
      if (userId) headers.append('userId', userId);
      const response = await fetch(`${SERVER_URL}/playlists/join/${playlistId}`, {
        credentials: 'include',
        headers
      });

      if (response.ok) {
        const updatedPlaylist: IPlaylist = await response.json();
        setPlaylist(updatedPlaylist);
        setJoined(true)
        setJoining(false);
      } else {
        const errorData = await response.json(); // you can log the error message sent by the server
        console.error(`Failed to join playlist with id ${playlistId}. Server responded with status ${response.status}. Server error message: ${errorData.error}`);
        setJoining(false);
      }
    } catch (err) {
      console.error(err);
      setJoining(false);
    }
  };

  const handleButtonHover = () => {
    setButtonHover(true);
  };

  const handleButtonLeave = () => {
    setButtonHover(false);
  };

  const buttonStyle = {
    background: 'transparent',
    border: '1px solid lightgray',
    color: 'lightgray',
    borderRadius: '10px',
    padding: '10px 20px',
    transition: 'background 0.1s',
    backgroundColor: buttonHover ? 'dimgray' : 'transparent',
  };

  return (
    <div className="join-container">
      <div className="logo" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
        {RImage && <img src={RImage} width={100} height={100} alt="Logo" />}
      </div>
      {loading || joining ? (
        <RingLoader color='lightgrey' />
      ) : (
        <>
          {playlist && playlist.embedLink && (
            <div className="playlist-users-container">
              <div className="playlist-container">
                <iframe
                  src={playlist.embedLink}
                  width="300"
                  height="380"
                  allow="encrypted-media"
                  style={{ border: 'none' }}
                ></iframe>
              </div>
              {playlist.users && (
                <div className="users-container">
                  {playlist.users.map((user, index) => (
                    <div
                      key={user.id || index}
                      className="user-profile"
                    >
                      {user.profileUrl && (
                        <a href={user.profileUrl} target="_blank" rel="noopener noreferrer">
                          {(() => {
                            const imageUrl = user.images?.[0]?.url;
                            return imageUrl ? (
                              <Avatar
                                style={{ width: '56px', height: '56px' }}
                                src={imageUrl}
                                alt={user.display_name}
                              />
                            ) : (
                              <Avatar
                                style={{ width: '56px', height: '56px' }}
                              >
                                <PersonIcon />
                              </Avatar>
                            )
                          })()}
                        </a>
                      )}
                      <p>{user.display_name || 'Anonymous'}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
          {loggedIn === true && joined === false && buttonStyle && <button
            style={buttonStyle}
            onMouseEnter={handleButtonHover}
            onMouseLeave={handleButtonLeave}
            onClick={joinPlaylist}
          >
            Join this Reso
          </button>}
        </>
      )}
    </div>
);

}

export const fetchPlaylist = async (playlistId: string, ) => {
  try {
    console.log(playlistId)
    const response = await fetch(`${SERVER_URL}/playlists/get/${playlistId}`, { credentials: 'include' });
    if (response.ok) {
      const playlist: IPlaylist = await response.json();
      if (playlist) console.log(playlist);
      return playlist
    } else {
      const errorData = await response.json()
      console.error(`Failed to fetch playlist with id ${playlistId}. Server responded with status ${response.status}. Server error message: ${errorData.error}`);
    }
    return undefined
  } catch (err) {
    console.error(err);
    return undefined
  }
};
